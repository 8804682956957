<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { fetchEquipmentsApi } from "@/api/common";
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Nouvelle chambre",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  import: { fetchEquipmentsApi },
  data() {
    return {
      title: "Nouvelle chambre",
      blocs: [],
      equipments: [],
      roomTypes: [],
      equipmentStatus: [
        {
          id: 'new',
          name: 'Nouveau'
        },
        {
          id: 'used',
          name: 'Utilisé'
        },
      ],
      items: [
        {
          text: "Infrastructures",
          href: "/",
        },
        {
          text: "Chambres",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      roomForm: {
        roomNumber: "",
        roomBloc: "",
        roomType: "",
        equipments: [
          {
            equipment: "",
            serial_no: "",
            inventory_no: "",
            status: "",
          },
        ],
        // roomPhone: "",
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    roomForm: {
      roomNumber: { required, maxLength: maxLength(64) },
      roomBloc: { required },
      roomType: { required },
    },
  },
  created: function() {
    this.getBlocs();
    this.getRoomsTypes();
    this.fetchEquipmentsList();
  },
  methods: {
    _validateRoomEquipmentData() {
      var valid = true;
      var roomEquipments = this.roomForm.equipments;
      roomEquipments.forEach((singleEquipmentEntry, index) => {
        index++;
        if (singleEquipmentEntry.equipment == "") {
          this.$toast.warning(
            "Veuillez selectionner l'équipement dans la line N°: " + index
          );
          valid = false;
        }

        if (singleEquipmentEntry.serial_no == "") {
          this.$toast.warning(
            "N° de série est obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }

        if (singleEquipmentEntry.inventory_no == "") {
          this.$toast.warning(
            "N° d'inventaire est obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }

        if (singleEquipmentEntry.status == "") {
          this.$toast.warning(
            "Status obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }
      });

      return valid;
    },
    AddformData() {
      if (this._validateRoomEquipmentData()) {
        this.roomForm.equipments.push({
          equipment: "",
          serial_no: "",
          inventory_no: "",
          status: "",
        });
      }
    },
    fetchEquipmentsList() {
      fetchEquipmentsApi()
        .then((res) => (this.equipments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    getBlocs() {
      var _this = this;
      this.$http
        .post("/infrastructure/blocs/list")
        .then((res) => {
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    getRoomsTypes() {
      var _this = this;
      this.$http
        .post("/infrastructure/room_types/list")
        .then((res) => {
          _this.roomTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var router = this.$router;
        this.$http
          .post("/infrastructure/rooms/store", this.roomForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                router.push({ name: "base.infrastructures.rooms" });
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Numéro de chambre</label>
                    <input
                      id="deptName"
                      v-model="roomForm.roomNumber"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid':
                          submitted && $v.roomForm.roomNumber.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.roomForm.roomNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roomForm.roomNumber.required"
                        >Le numéro de chambre est obligatoire.</span
                      >
                      <span v-if="!$v.roomForm.roomNumber.maxLength"
                        >La taille du champs est limitée a 64 charactères.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Bloc</label>
                    <select
                      v-model="roomForm.roomBloc"
                      :class="{
                        'is-invalid': submitted && $v.roomForm.roomBloc.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected>Sélectionner une classe</option>
                      <option
                        v-for="bloc in blocs"
                        v-bind:value="bloc.id"
                        v-bind:key="bloc.id"
                      >
                        {{ bloc.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.roomForm.roomBloc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roomForm.roomBloc.required"
                        >Le bloc est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Type</label>
                    <select
                      v-model="roomForm.roomType"
                      :class="{
                        'is-invalid': submitted && $v.roomForm.roomType.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected></option>
                      <option
                        v-for="type in roomTypes"
                        v-bind:value="type.id"
                        v-bind:key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.roomForm.roomType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roomForm.roomType.required"
                        >Le type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h3>Fiche d'inventaire:</h3>
                  <hr />
                  <div>
                    <div
                      v-for="(equipment, index) in roomForm.equipments"
                      :key="equipment.id"
                      class="row"
                    >
                      <div class="form-group col-lg-3">
                        <label for="message">Equipement</label>
                        <multiselect
                          v-model="equipment.equipment"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="equipments"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="resume">N° Série</label>
                       <input
                          id="subject"
                          v-model="equipment.serial_no"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-lg-2">
                        <label for="subject">N° Inventaire</label>
                        <input
                          id="subject"
                          v-model="equipment.inventory_no"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-lg-3">
                        <label for="message">Status</label>
                        <multiselect
                          v-model="equipment.status"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="equipmentStatus"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>

                      <div class="col-lg-1 align-self-center">
                        <a
                          v-if="roomForm.equipments.length > 1 && index != 0"
                          href="javascript:void(0)"
                          ><i
                            class="bx bx-trash-alt"
                            @click="deleteRow(index)"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <input
                      type="button"
                      class="btn btn-success mt-3 mt-lg-0"
                      value="Ajouter"
                      @click="AddformData"
                    />
                  </div>
                </div>
              </div> <br>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="roomForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
